<div class="no-print">
  <div class="w-full fixed top-0 z-2">
    <div class="w-full toolbar flex align-items-center flex-wrap">
      <div class=" flex flex-1 align-items-center justify-content-center">
        <p-fileUpload (onUpload)="onUpload($event)" [auto]="true"
                      (onError)="onError($event)"
                      [accept]="acceptedFileTypes"
                      chooseIcon="pi pi-file-excel"
                      chooseLabel="Wgraj" mode="basic"
                      name="report" styleClass="hidden md:block" url="api/report">
        </p-fileUpload>
        <p-fileUpload (onUpload)="onUpload($event)" [auto]="true"
                      (onError)="onError($event)"
                      [accept]="acceptedFileTypes"
                      chooseIcon="pi pi-file-excel"
                      chooseLabel=""
                      mode="basic"
                      name="report" styleClass="inline-flex md:hidden p-button" url="api/report">
        </p-fileUpload>
      </div>

      <div class="flex align-items-center justify-content-center flex-1">
        <label (click)="showContractForm()" *ngIf="report" class="p-button" pRipple>
          <span class="block"><i class="pi pi-file"></i></span>
          <span class="hidden md:block ml-2">Umowa</span>
        </label>

      </div>

      <div class="flex align-items-center justify-content-center flex-1">
        <label *ngIf="report" class="p-button" pRipple>
          <input (change)="uploadSign($event)" accept="image/png, image/jpeg" id="sign" type="file"/>
          <span class="block"><i class="pi pi-pencil"></i></span>
          <span class="hidden md:block ml-2">Podpis</span>
        </label>
      </div>

      <div class=" flex align-items-center flex-1 justify-content-center">
        <label (click)="print()" *ngIf="report" class="p-button" pRipple>
          <span class="block"><i class="pi pi-print"></i></span>
          <span class="hidden md:block ml-2">Wydruk</span>
        </label>
      </div>
    </div>
  </div>
</div>

<div *ngIf="report" class="page">
  <p class="h5 text-center font-semibold"> Oświadczenie o miesięcznej liczbie godzin świadczenia usług </p>
  <p class="h5 text-center font-semibold">oraz ich rodzaju</p>
  <p class="h5 text-center font-semibold"> w ramach umowy o świadczenie usług </p>

  <p class="h5 text-justify my-2 ">
    Ja, niżej {{ report.name | genderSign }} {{ report.name }}, oświadczam, iż w okresie
    {{ report.period }} {{ report.name | genderProvide }} usługi w ramach
    Umowy nr {{ contract }} o Świadczenie Usług, w
    następującym wymiarze:
  </p>

  <div class="mt-4">
    <div class="flex justify-content-end">
      <button (click)="editReport()" [label]="edit ? 'Zapisz' : 'Zmodyfikuj zadania'" class="no-print" pButton
              pRipple></button>
    </div>
    <table>
      <thead>
      <th class=" w-60-px text-center">Lp.</th>
      <th class="text-center">Rodzaj Usługi</th>
      <th class="w-100-px text-center">Liczba godzin</th>
      </thead>
      <tbody>
      <tr *ngFor="let task of report.tasks;  let i = index">
        <td class="text-right">{{ i + 1 }}</td>
        <td *ngIf="!edit" class="text-left">
          <span class="font-semibold">{{ task.key }}</span>
          {{ task.summary }}
        </td>
        <td *ngIf="edit" class="p-0 no-print">
          <div class="flex align-items-center">
            <input [(ngModel)]="task.key" class="w-7rem h-edit mr-edit ml-edit font-bold" pInputText/>
            <input [(ngModel)]="task.summary" class="flex-1 h-edit mr-edit" pInputText/>
          </div>
        </td>
        <td *ngIf="!edit" class="text-center">{{ task.hours }}</td>
        <td *ngIf="edit" class="p-0 no-print">
          <div class="flex align-items-center">
            <input [(ngModel)]="task.hours" class="h-edit mr-edit ml-edit w-full text-center" pInputText/>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="grid sign mt-5">
    <div class="col-4 col-offset-8 text-center">
      Suma: {{ report.sumHours() }}
    </div>
    <div class="col-4 col-offset-8 font-semibold text-center">
      Zleceniobiorca
    </div>
    <div class="col-4 col-offset-8 border-top mt-2 text-center">
      {{ report.name | gender }} {{ report.name }}
    </div>
    <div class="col-4 col-offset-8 text-center">
      data: {{ report.date }}
    </div>
    <div class="col-4 col-offset-8 text-center sign-img" [style.top]="imgSignTop+'px'" [style.left]="imgSignLeft+'px'">
      <img #signImg (dragend)="imgDragEnd($event)" (dragstart)="imgDragStart($event)" class="{{displayImageClass}}"
           src=""
           width="250"/>
    </div>
    <p-contextMenu [model]="signMenu" [target]="signImg" appendTo="body"/>

  </div>
</div>

<div class="no-print">
  <div class="w-screen fixed bottom-0 left-0 right-0">
    <div class="w-screen toolbar flex align-items-center px-3">
      <div class="flex align-items-center justify-content-start w-3rem">
        TIIWP
      </div>
      <div class="flex-grow-1 flex align-items-center justify-content-center">
        Wersja: {{ actuatorService.version }}
      </div>

      <div class="flex align-items-center justify-content-end w-3rem">
        <a href="https://github.com/pantalejmon/ppl-report-generator"> <i class="pi pi-github text-2xl mr-2"></i></a>
      </div>
    </div>
  </div>
</div>

<p-sidebar #NavSideBar (onHide)="NavSideBar.destroyModal()" [(visible)]="displayContractForm" appendTo="body"
           dismissible=true
           position="bottom" styleClass="p-sidebar-md">
  <div class=" grid">
    <div class="col-12 md:col-4 md:col-offset-4">
      <div class="p-inputgroup">
        <input (ngModelChange)="saveContractToStorage($event)" [(ngModel)]="contract" pInputText
               placeholder="Numer umowy" type="text">
        <button (click)="hideContractForm()" icon="pi pi-save" pButton pRipple type="button"></button>
      </div>
    </div>
  </div>
</p-sidebar>
